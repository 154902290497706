/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useState } from "react";
import { graphql } from "gatsby";
import blocksToHtml from "@sanity/block-content-to-html";
import client from "@sanity/client";
import get from "lodash/get";
import queryString from "query-string";

import {
  getArtistName,
  getArtistPath,
  getArtworkPath,
  getExhibitionPath,
  getNewsArticlePath,
  getPublicationPath,
  getViewingRoomPath,
} from "utils";

const colorPrimary = "#6C737A";
const colorSecondary = "#9FABAC";
const fontFamilyHeading = "'Josefin Sans', sans-serif";
const fontFamilyBody = "Arial, Helvetica, sans-serif";
// const fontFamilyBody = "'Cormorant Garamond', 'Georgia', serif";
const fontSizeBody = "14px";
const fontSizeHeading = "24px";
const fontWeightHeading = "400";
const lineHeightBody = "1.5";

// const googleFontsImport = `@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');`;
const googleFontsImport = `@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');`;

function getBlocksToHtml(blocks) {
  client({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
    apiVersion: "2021-03-25",
    useCdn: true,
  });

  const h = blocksToHtml.h;

  return blocksToHtml({
    blocks,
    serializers: {
      marks: {
        link: ({ children, href }) => h("a", { children, href }),

        internalLink: ({ children, mark: { reference } }) => {
          let href = "";
          let download = null;

          if (reference._type === "download") {
            href = reference?.file?.asset?.url || "";
            download = reference.name;
          }

          if (reference._type === "artist") {
            href = process.env.GATSBY_ROOT_URL + getArtistPath(reference);
          }

          if (reference._type === "artwork") {
            href = process.env.GATSBY_ROOT_URL + getArtworkPath(reference);
          }

          if (reference._type === "exhibition") {
            href =
              process.env.GATSBY_ROOT_URL +
              getExhibitionPath({
                name: reference.name,
                year: reference.start_date.split("-")[0],
              });
          }

          if (reference._type === "publication") {
            href = process.env.GATSBY_ROOT_URL + getPublicationPath(reference);
          }

          if (reference._type === "viewing_room") {
            href = process.env.GATSBY_ROOT_URL + getViewingRoomPath(reference);
          }

          if (reference._type === "news_article") {
            href = getNewsArticlePath(reference);
          }

          if (reference._type === "installationShot") {
            href =
              process.env.GATSBY_ROOT_URL +
              getExhibitionPath({
                name: reference.forExhibition.name,
                year: reference.forExhibition.start_date.split("-")[0],
              }) +
              "#" +
              reference._id;
          }

          if (download) {
            return h("a", { children, href, download });
          }

          return h("a", { children, href });
        },
      },
    },
  });
}

function getNewsletterItemHTML({
  linkTo,
  title,
  subtitle,
  headerTextHTML,
  imageSrc,
  imageCredit,
  content,
}) {
  return [
    title
      ? `<tr>
      <td style="font-family: ${fontFamilyHeading}; font-size: ${fontSizeHeading}; padding: 30px 0 0; line-height: ${lineHeightBody}; color: ${colorPrimary}; font-weight: ${fontWeightHeading};">
        <p style="font-family: ${fontFamilyHeading}; font-size: ${fontSizeHeading}; text-align: left; margin: 0; color: ${colorPrimary}; font-weight: ${fontWeightHeading};">
        ${
          linkTo
            ? `<a href="${linkTo}" style="color: ${colorPrimary}; text-decoration: none;" target="_blank">`
            : ""
        }
        ${title}
        ${linkTo ? `</a>` : ""}
        </p>
        </td>
        </tr>`
      : "",

    subtitle
      ? `<tr>
          <td style="text-align: left; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; color: ${colorPrimary}; padding: 0; line-height: ${lineHeightBody};">
            <p style="font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; text-align: left; margin: 0; color: ${colorPrimary}; font-weight: ${fontWeightHeading};">
              ${subtitle}
            </p>
          </td>
        </tr>`
      : "",

    headerTextHTML
      ? `<tr>
      <td style="text-align: left; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; color: ${colorPrimary}; padding: 8px 0 0 0; line-height: ${lineHeightBody};">
        ${headerTextHTML}
      </td>
    </tr>`
      : "",

    imageSrc
      ? `<tr>
      <td style="padding: 20px 0;">
      ${linkTo ? `<a href="${linkTo}">` : ""}
        <img src="${imageSrc}" style="display: block;" />
        ${linkTo ? `</a>` : ""}
        ${
          imageCredit
            ? `<p style="text-align: left; padding: 4px 0 0; margin: 0; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; color: ${colorPrimary};">${imageCredit}</p>`
            : ""
        }
      </td>
    </tr>`
      : "",

    content
      ? `<tr>
      <td style="text-align: left; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; color: ${colorPrimary}; line-height: ${lineHeightBody}; padding: 0 0 8px 0;">
        ${getBlocksToHtml(content)}
      </td>
    </tr>`
      : "",
  ]
    .filter(Boolean)
    .join("\n");
}

function NewsletterPage({
  data: {
    newsletter,
    sanitySiteSettings: {
      address,
      contactEmail,
      contactPhoneNumber,
      instagramUrl,
      twitterUrl,
    },
  },
  location: { search },
}) {
  const [isLoading, setIsLoading] = useState(true);

  const codeOnly = Object.prototype.hasOwnProperty.call(
    queryString.parse(search),
    "code"
  );

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const introductionHTML =
    newsletter?.introduction && getBlocksToHtml(newsletter.introduction);

  const value = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Austin Desmond Newsletter</title>
  </head>

  <body bgcolor="fff" style="background-color:#fff;">
    <div style="text-align: center;" align="center">
      <style>
        ${googleFontsImport}
      </style>

      <style>
      html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}li{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit}input,textarea,select{*font-size:100%}legend{color:#000}

      a {
        color: #999;
        text-decoration: none;
      }

      p {
        padding: 0;
        margin: 0 0 10px 0;
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style:italic;
      }

      img {
        max-width: 100%;
      }
      </style>

      <table border="0" style="background: #fff; max-width: 600px; width: 100%; text-align:center;" align="center">
        <tr>
          <td style="width: 20px;"></td>
          <td align="center" style="padding: 10px 0;">
            <table style="width: 100%; max-width: 560px;" align="center">
              <tr>
                <td style="padding: 10px 0;">
                  <a href="${
                    process.env.GATSBY_ROOT_URL
                  }" target="_blank"><img src="${
    process.env.GATSBY_ROOT_URL
  }/austin-desmond-high-res.png" alt="Austin / Desmond Fine Art" height="60" style="max-height: 60px; height: auto;" /></a>
                </td>
              </tr>

              ${
                introductionHTML
                  ? `
              <tr>
                <td style="text-align: left; color: ${colorPrimary}; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; line-height: ${lineHeightBody}; padding: 8px 0 0;">
                  ${introductionHTML}
                </td>
              </tr>
              `
                  : ""
              }

              ${newsletter.newsletter_items
                .map(
                  ({
                    title,
                    headerText,
                    content,
                    image,
                    image_credit,
                    featured_item,
                  }) => {
                    if (featured_item) {
                      if (featured_item._type === "artwork") {
                        return getNewsletterItemHTML({
                          linkTo: `${
                            process.env.GATSBY_ROOT_URL
                          }${getArtworkPath(featured_item)}`,
                          title:
                            title ||
                            [
                              featured_item?.work_title || featured_item?.name,
                              featured_item?.work_date,
                            ]
                              .filter(Boolean)
                              .join(", "),
                          subtitle:
                            featured_item.artist &&
                            getArtistName(featured_item.artist),
                          imageSrc:
                            image?.asset?.gatsbyImageData?.images?.fallback
                              ?.src ||
                            featured_item?.work_image?.asset?.gatsbyImageData
                              ?.images?.fallback?.src,
                          content: content || featured_item?.content,
                          imageCredit:
                            image_credit ||
                            [
                              getArtistName(featured_item.artist),
                              featured_item?.work_title || featured_item?.name,
                              featured_item?.work_date,
                            ]
                              .filter(Boolean)
                              .join(", "),
                        });
                      }

                      if (featured_item._type === "exhibition") {
                        const featuredArtwork =
                          get(featured_item, ["featured_artwork"]) ||
                          get(featured_item, ["artworks", 0]) ||
                          {};

                        const imageToUse = image || featured_item?.image;

                        return getNewsletterItemHTML({
                          linkTo: `${
                            process.env.GATSBY_ROOT_URL
                          }${getExhibitionPath(featured_item)}`,
                          title: title || featured_item?.name,
                          subtitle: [
                            featured_item?.start_date,
                            featured_item?.end_date,
                          ]
                            .filter(Boolean)
                            .join(" - "),
                          imageSrc:
                            imageToUse?.asset?.gatsbyImageData?.images?.fallback
                              ?.src ||
                            featuredArtwork?.work_image?.asset?.gatsbyImageData
                              ?.images?.fallback?.src,
                          imageCredit:
                            image_credit ||
                            [
                              featuredArtwork.artist &&
                                getArtistName(featuredArtwork.artist),
                              featuredArtwork?.work_title ||
                                featuredArtwork?.name,
                              featuredArtwork?.work_date,
                            ]
                              .filter(Boolean)
                              .join(", "),
                          content: content || featured_item?.content,
                        });
                      }

                      if (featured_item._type === "publication") {
                        return getNewsletterItemHTML({
                          linkTo: `${
                            process.env.GATSBY_ROOT_URL
                          }${getPublicationPath(featured_item)}`,
                          title: title || featured_item?.title,
                          imageSrc:
                            image?.asset?.gatsbyImageData?.images?.fallback
                              ?.src ||
                            featured_item?.image?.asset?.gatsbyImageData?.images
                              ?.fallback?.src,
                          content: content || featured_item?.content,
                          imageCredit: image_credit,
                        });
                      }

                      if (featured_item._type === "viewing_room") {
                        return getNewsletterItemHTML({
                          linkTo: `${
                            process.env.GATSBY_ROOT_URL
                          }${getViewingRoomPath(featured_item)}`,
                          title: title || featured_item?.title,
                          imageSrc:
                            image?.asset?.gatsbyImageData?.images?.fallback
                              ?.src ||
                            featured_item?.artworks[0]?.work_image?.asset
                              ?.gatsbyImageData?.images?.fallback?.src,
                          content: content || featured_item?.content,
                          imageCredit:
                            image_credit ||
                            [
                              getArtistName(featured_item?.artworks[0].artist),
                              featured_item?.artworks[0]?.work_title ||
                                featured_item?.artworks[0]?.name,
                              featured_item?.artworks[0]?.work_date,
                            ]
                              .filter(Boolean)
                              .join(", "),
                        });
                      }
                    }

                    return getNewsletterItemHTML({
                      title,
                      headerTextHTML: headerText && getBlocksToHtml(headerText),
                      imageSrc:
                        image?.asset?.gatsbyImageData?.images?.fallback?.src,
                      imageCredit: image_credit,
                      content,
                    });
                  }
                )
                .join("")}

              <tr>
                <td style="padding: 30px 0 0;">
                </td>
              </tr>
              <tr>
                <td style="border-top: 1px solid ${colorSecondary};">
                  <p style="text-align: left; color: ${colorPrimary}; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody}; line-height: ${lineHeightBody}; margin: 8px 0;">
                    Austin / Desmond Fine Art<br />
                    ${address}
                    <br />
                    ${
                      contactPhoneNumber
                        ? `T:  <a href="tel:${contactPhoneNumber}" style="color: ${colorPrimary}; text-decoration: none;">${contactPhoneNumber}</a><br />`
                        : ""
                    }
                    ${
                      contactEmail
                        ? `E:  <a href="mailto:${contactEmail}" style="color: ${colorPrimary}; text-decoration: none;">${contactEmail}</a><br />`
                        : ""
                    }
                    W: <a href="${
                      process.env.GATSBY_ROOT_URL
                    }" target="_blank" style="color: ${colorPrimary}; text-decoration: none;">${process.env.GATSBY_ROOT_URL.replace(
    /^https?:\/\//,
    ""
  )}</a>
                  </p>

                  <p style="text-align: left; color: ${colorPrimary}; padding: 8px 0; font-family: ${fontFamilyBody}; font-size: ${fontSizeBody};">
                    ${
                      instagramUrl
                        ? `<a href="${instagramUrl}" target="_blank" style="color: ${colorPrimary}; text-decoration: none;"><img src="${process.env.GATSBY_ROOT_URL}/instagram.png" alt="Instagram" height="22" /></a>&nbsp;`
                        : ""
                    }

                    ${
                      twitterUrl
                        ? `<a href="${twitterUrl}" target="_blank" style="color: ${colorPrimary}; text-decoration: none;"><img src="${process.env.GATSBY_ROOT_URL}/twitter.png" alt="Twitter" height="22" /></a>&nbsp;`
                        : ""
                    }
                  </p>
                </td>
              </tr>
            </table>
          </td>
          <td style="width: 20px;"></td>
        </tr>
      </table>
    </div>
  </body>
</html>`;

  if (isLoading) {
    return <div />;
  }

  return (
    <div sx={{ display: "flex", flexDirection: "row", height: "100vh" }}>
      {!codeOnly && (
        <div
          sx={{
            height: "100%",
            overflow: "auto",
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "50%",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      )}
      <div
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "50%",
          borderLeft: "solid 1px #ccc",
          height: "100%",
          overflow: "auto",
        }}
      >
        <textarea
          sx={{
            resize: "none",
            width: "100%",
            height: "100%",
            padding: "20px",
            border: "none",
          }}
          readOnly
          defaultValue={value}
        />
      </div>
    </div>
  );
}

export const query = graphql`
  query Newsletter($id: String) {
    newsletter: sanityNewsletter(_id: { eq: $id }) {
      introduction: _rawIntroduction(resolveReferences: { maxDepth: 10 })
      _id
      date
      title
      newsletter_items {
        _id
        title
        image_credit
        image {
          asset {
            gatsbyImageData(width: 600)
          }
        }
        content: _rawContent(resolveReferences: { maxDepth: 10 })
        headerText: _rawHeaderText(resolveReferences: { maxDepth: 10 })
        featured_item {
          ... on SanityPublication {
            id: _id
            title
            _type
            content: _rawDetails(resolveReferences: { maxDepth: 10 })
            image {
              asset {
                gatsbyImageData(width: 600)
              }
            }
          }

          ... on SanityExhibition {
            id: _id
            name
            _type
            year: start_date(formatString: "YYYY")
            start_date(formatString: "Do MMMM YYYY")
            end_date(formatString: "Do MMMM YYYY")
            content: _rawDescription(resolveReferences: { maxDepth: 10 })
            image {
              asset {
                gatsbyImageData(width: 600)
              }
            }
            featured_artwork {
              _id
              name
              work_image {
                asset {
                  gatsbyImageData(width: 600)
                }
              }
              work_title
              artist {
                _id
                create_web_page
                first_name
                last_name
                yearOfDeath: date_of_death(formatString: "yyyy")
                yearOfBirth: date_of_birth(formatString: "yyyy")
              }
              work_date
            }
            artworks {
              artist {
                create_web_page
                first_name
                last_name
                yearOfDeath: date_of_death(formatString: "yyyy")
                yearOfBirth: date_of_birth(formatString: "yyyy")
              }
              name
              work_title
              work_date
              work_image {
                asset {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }

          ... on SanityArtwork {
            _id
            name
            work_title
            _type
            artist {
              create_web_page
              last_name
              first_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            work_date
            work_image {
              asset {
                gatsbyImageData(width: 600)
              }
            }
            content: _rawDetails(resolveReferences: { maxDepth: 10 })
          }

          ... on SanityViewingRoom {
            _id
            _type
            title
            content: _rawContent(resolveReferences: { maxDepth: 10 })
            artworks {
              artist {
                create_web_page
                first_name
                last_name
                yearOfDeath: date_of_death(formatString: "yyyy")
                yearOfBirth: date_of_birth(formatString: "yyyy")
              }
              name
              work_title
              work_date
              work_image {
                asset {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
        }
      }
    }

    sanitySiteSettings {
      address
      contactEmail: contact_email
      contactPhoneNumber: contact_phone_number
      instagramUrl: instagram_url
      twitterUrl: twitter_url
    }
  }
`;

export default NewsletterPage;
